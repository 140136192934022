import { AxiosRequestConfig } from 'axios'
import { QueryParams } from './types'
import ApiService from '../ApiService/ApiService'

function getUrl(path: string) {
  return `${path}`
}

class GenericService {
  public static instance: GenericService

  constructor() {
    if (GenericService.instance) {
      return GenericService.instance
    }
    GenericService.instance = this
  }

  list<T = any>(model: string, query?: QueryParams, options?: AxiosRequestConfig) {
    const opts: AxiosRequestConfig = options || {}
    if (query) {
      opts.params = query
    }
    return ApiService.get<T>(getUrl(model), opts)
  }

  get<T = any>(model: string, id: number, options?: AxiosRequestConfig) {
    return ApiService.get<T>(getUrl(`${model}/${id}`), options)
  }

  create<T, R = any>(model: string, data: R, options?: AxiosRequestConfig) {
    return ApiService.post<T, R>(model, data, options)
  }

  update<T, R = any>(model: string, id: number, data: R, options?: AxiosRequestConfig) {
    return ApiService.post<T, R>(getUrl(`${model}/${id}`), data, options)
  }

  delete<T = any>(model: string, id: number, options?: AxiosRequestConfig) {
    return ApiService.delete<T>(getUrl(`${model}/${id}`), options)
  }
}

const instance = new GenericService()

Object.freeze(instance)

export default instance
